import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {User} from '../../models/user-model';


@Injectable()
export class CurrentUserService {


  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
    private _router: Router,
  ) {
  }

  public get currentUserValue(): User | null {
    this.currentUserSubject.next(JSON.parse(localStorage.getItem('user')));
    return this.currentUserSubject ? this.currentUserSubject.value : null;
  }

  public get currentInfluencerMetricsValue(): any | null {
    this.currentUserSubject.next(JSON.parse(localStorage.getItem('metrics')));
    return this.currentUserSubject ? this.currentUserSubject.value : null;
  }


  setUser(user: User) {
    this.currentUserSubject.next(user);
    localStorage.setItem('user', JSON.stringify(user));
  }

  setInfluencerMetrics(metrics) {
    this.currentUserSubject.next(metrics);
    localStorage.setItem('metrics', JSON.stringify(metrics));
  }


  userLogOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('metrics');
    this.currentUserSubject.next(null);
    location.reload();
  }

}
