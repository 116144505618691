import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from './authentication.actions';
import {
    switchMap,
    catchError,
    map,
} from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationState } from './authentication.reducers';
import {CurrentUserService} from "../../core/services/auth/current-user.service";
import {User} from "../../core/models/user-model";
import * as influencerActions from "../../store/influencer/influencer.actions";



@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    public router: Router,
    private service: AuthenticationService,
    private store: Store<AuthenticationState>,
    private _currentUserService: CurrentUserService,
  ) {}


  loginFacebookToken$ = createEffect(() => this.actions$.pipe(
      ofType(actions.loginFacebookToken),
      switchMap( action => {
          return this.service.loginFacebookToken(action.facebookToken).pipe(
              map(instagramAccounts => {
                  return actions.loginFacebookTokenSuccess({ instagramAccounts }); }),
              catchError(error => of(actions.loginFacebookTokenFail({ error })))
          );
      })
  ));

    saveFacebookToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveFacebookToken),
            switchMap(action => {
                return of(actions.saveFacebookTokenSuccess({ facebookToken: action.facebookToken }));
            }),
        )
    );

    getCognitoCredentials$ = createEffect(() => this.actions$.pipe(
        ofType(actions.getCognitoCredentials),
        switchMap( action => {
                return this.service.getCognitoCredentials(action.instagramAccount).pipe(
                    map(credentials => {
                        this.router.navigate(['/app/profile/']);
                        // @ts-ignore
                        this._currentUserService.setUser(new User(credentials.body.SecretKey, credentials.body.SessionToken, credentials.body.Expiration, credentials.body.AccessKeyId));
                        this.store.dispatch(influencerActions.getInfluencerAvatar());
                        return actions.getCognitoCredentialsSuccess({ user: credentials.body }); }),
                    catchError(error => of(actions.getCognitoCredentialsFail({ error })))
                );
            }
        )
    ));


}
