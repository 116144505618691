import { createAction, props } from '@ngrx/store';


// guardar en cache el token que me ha dado facebook
export const saveFacebookToken = createAction(
    '[Auth] Save facebook token',
    props<{ facebookToken: string}>()
);
export const saveFacebookTokenSuccess = createAction(
    '[Auth] Save facebook token success',
    props<{ facebookToken: string }>()
);
export const saveFacebookTokenFail = createAction(
    '[Auth] Save facebook token fail',
    props<{ error: any }>()
);

/// login con el token de facebook para sacar las cuentas de instagram
export const loginFacebookToken = createAction(
  '[Auth] Login facebook token',
  props<{ facebookToken: string}>()
);
export const loginFacebookTokenSuccess = createAction(
  '[Auth] Login facebook token success',
    props<{ instagramAccounts: any }>()
);
export const loginFacebookTokenFail = createAction(
  '[Auth] Login facebook token fail',
  props<{ error: any }>()
);

// login con la cuenta de instagram para conseguir los cognito credentials
export const getCognitoCredentials = createAction(
    '[Auth] Get Cognito Credentials',
    props<{ instagramAccount: any}>()
);
export const getCognitoCredentialsSuccess = createAction(
    '[Auth] Get Cognito Credentials success',
    props<{ user: any }>()
);
export const getCognitoCredentialsFail = createAction(
    '[Auth] Get Cognito Credentials fail',
    props<{ error: any }>()
);



export const clearErrors = createAction('[Auth] clear errors');
export const logout = createAction('[Auth] logout');
