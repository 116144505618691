export class AwsSignatureInputData {
  method: string;
  service: string;
  host: string;
  region: string;
  requestParameters: string;
  contentType: string;
  accessKey: string;
  secretKey: string;
  canonicalUri: string;
  canonicalQuerystring: string;
  sessionToken: string;

  constructor(method: string, canonicalUri: string, host: string, region: string, service: string, accessKey: string, secretKey: string, contentType: string, requestParameters: string, canonicalQuerystring: string, sessionToken: string) {
    this.method = method;
    this.canonicalUri = canonicalUri;
    this.host = host;
    this.region = region;
    this.service = service;
    this.accessKey = accessKey;
    this.secretKey = secretKey;
    this.contentType = contentType;
    this.requestParameters = requestParameters;
    this.canonicalQuerystring = canonicalQuerystring;
    this.sessionToken = sessionToken;
  }
}
