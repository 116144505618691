import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    switchMap,
    catchError,
    map,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import * as actions from './app.actions';
import {AppService} from "./app.service";


@Injectable()
export class AppEffects {
    constructor(
        public router: Router,
        private actions$: Actions,
        private _appService: AppService
    ) {}


    getPublicUserGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getPublicUserGroup),
            switchMap(action => {
                return this._appService.getPublicUserGroup(action.typeUserGroup, action.queryValue).pipe(
                    map(userGroup => {
                        this._appService.processTheme(userGroup['theme_style']);
                        return actions.getPublicUserGroupSuccess( { userGroup });
                    }),
                    catchError(error => of(actions.getPublicUserGroupFail({ error })))
                );
            })
        )
    );

}
