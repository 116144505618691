import {Observable, Subscriber} from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CurrentUserService} from '../services/auth/current-user.service';
import {AwsSignatureInputData} from '../aws/aws-signature-input.model';
import {AwsSignature} from '../aws/aws-signature';
import {environment} from "../../../environments/environment";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {


  constructor(private _currentUser: CurrentUserService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._currentUser.currentUserValue;
    let currentUrl = environment.aws_resources.lambda_endpoint.api;

    let handleObs;

    if (currentUser) {
      const signature = new AwsSignature().generateSignature(
        new AwsSignatureInputData(
          req.method,
          req.url.split('.io')[1],
          currentUrl.split('/')[2],
          'eu-west-1',
          'execute-api',
          currentUser.accessKeyId,
          currentUser.secretKey,
          'application/x-www-form-urlencoded',
          req.body ? JSON.stringify(req.body) : '',
            req.urlWithParams.split('?')[1] ? req.urlWithParams.split('?')[1]: '',
          currentUser.sessionToken)
      );
      handleObs = req.clone(
        {
          url: req.url,
          body: req.body,
          setHeaders: {
            'Authorization': signature['Authorization'],
            'Content-Type': signature['Content-Type'],
            'X-Amz-Date': signature['X-Amz-Date'],
            'X-Amz-Security-Token': signature['X-Amz-Security-Token']
          }
        });
    } else
      handleObs = req.clone({url: req.url});

    const observable = new Observable<HttpEvent<any>>((subscriber) => {

      const originalRequestSubscription = next.handle(handleObs)
          .subscribe((response) => {
                subscriber.next(response);
              },
              (err) => {
                if (err.status === 403 || err.status === 401)
                  this._errorHandler(subscriber, req);
                else
                  subscriber.error(err);

              },
              () => {
                subscriber.complete();
              });
      return () => {
        originalRequestSubscription.unsubscribe();
      };
    });

    return observable;
  }

  private _errorHandler(subscriber: Subscriber<any>, request: HttpRequest<any>) {
    this._currentUser.userLogOut();
  }

}
