import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './influencer.actions';

export const influencerKey = 'influencer';

export interface InfluencerState {
    avatar: any,
}

export const initialInfluencerState = {
    avatar: null,
};

export const influencerReducers = {
    getInfluencerAvatar(state) {
        return { ...state};
    },
    getInfluencerAvatarSuccess(state, { avatar }) {
        return { ...state, avatar};
    },
    getInfluencerAvatarError(state, { error }) {
        return { ...state, error: 'Error getting avatar' };
    },

};

export function reducer(
    state: InfluencerState | undefined,
    action: Action
) {
    return createReducer(
        initialInfluencerState,
        on(actions.getInfluencerAvatar, influencerReducers.getInfluencerAvatar),
        on(actions.getInfluencerAvatarSuccess, influencerReducers.getInfluencerAvatarSuccess),
        on(actions.getInfluencerAvatarFail, influencerReducers.getInfluencerAvatarError),
    )(state, action);
}
