import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { SpinnerComponent } from './components/spinner/spinner.component';
import {AngularMaterialModule} from "../angular-material.module";
import { NavbarComponent } from './components/navbar/navbar.component';
import {RouterModule} from "@angular/router";
import {CurrentUserService} from "../../core/services/auth/current-user.service";
import { ChartsModule } from 'ng2-charts';
import {GaugeChartModule} from "angular-gauge-chart";

@NgModule({
  declarations: [
    SpinnerComponent,
    NavbarComponent,
  ],
  imports: [
    AngularMaterialModule,
    CommonModule,
    RouterModule,
    ChartsModule,
    GaugeChartModule
  ],
  providers: [
    CurrentUserService,
  ],
  exports: [
    SpinnerComponent,
    NavbarComponent,
    ChartsModule,
    GaugeChartModule
  ]
})
export class SharedModule {}
