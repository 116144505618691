import { CognitoUser } from '../../types/user';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './authentication.actions';

export const authenticationKey = 'authentication';

export interface AuthenticationState {
  user: CognitoUser;
  instagramAccounts: InstagramAccounts;
  email: string;
  facebookToken: string;
}

export interface InstagramAccounts {
  instagram_id: string;
  instagram_business_account_id: string;
  instagram_username: string;
  instagram_profile_picture_url: string;
  facebook_id: string;
}

export const initialAuthenticationState = {
  user: null,
  email: null,
  accessSets: {},
  loginLoading: false,
  loginError: null,
};

function _clearErrors() {
  return {
    loginError: null,
    signUpError: null,
  };
}

export const authenticationReducers = {
  clearErrors(state) {
    return {
      ...state,
      ..._clearErrors(),
    };
  },
  loginFacebookToken(state) {
    return { ...state, loginLoading: true };
  },
  loginFacebookTokenSuccess(state, { instagramAccounts }) {
    return { ...state, loginLoading: false, instagramAccounts, ..._clearErrors() };
  },
  loginFacebookTokenError(state, { error }) {
    return { ...state, loginLoading: false, loginError: 'Error, cant get the instagram accounts' };
  },
  /// save facebook token
  saveFacebookToken(state) {
    return { ...state, saveFacebookTokenLoading: true };
  },
  saveFacebookTokenSuccess(state, { facebookToken }) {
    return { ...state, saveFacebookTokenLoading: false, facebookToken, ..._clearErrors() };
  },
  saveFacebookTokenError(state, { error }) {
    return { ...state, loginLoading: false, loginError: 'Error saving facebook token' };
  },

};

export function reducer(
  state: AuthenticationState | undefined,
  action: Action
) {
  return createReducer(
    initialAuthenticationState,
    on(actions.clearErrors, authenticationReducers.clearErrors),
    on(actions.loginFacebookToken, authenticationReducers.loginFacebookToken),
    on(actions.loginFacebookTokenSuccess, authenticationReducers.loginFacebookTokenSuccess),
    on(actions.loginFacebookTokenFail, authenticationReducers.loginFacebookTokenError),
    on(actions.saveFacebookToken, authenticationReducers.saveFacebookToken),
    on(actions.saveFacebookTokenSuccess, authenticationReducers.saveFacebookTokenSuccess),
    on(actions.saveFacebookTokenFail, authenticationReducers.saveFacebookTokenError),
  )(state, action);
}
