export class User {
  public secretKey: string;
  public sessionToken: string;
  public expiration: string;
  public accessKeyId: string;

  constructor(secretKey: string, sessionToken: string, expiration: string, accessKeyId: string) {
    this.secretKey = secretKey;
    this.sessionToken = sessionToken;
    this.expiration = expiration;
    this.accessKeyId = accessKeyId;
  }
}
