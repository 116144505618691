import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { reducers, metaReducers } from './index';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationStoreModule } from './auth/authentication-store.module';
import {AppEffects} from "./app/app.effects";
import {InfluencerEffects} from "./influencer/influencer.effects";
import * as fromApp from './app/app.reducers';
import * as fromInfluencer from './influencer/influencer.reducers';



@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        name: 'PAX',
        logOnly: environment.production
      })
      : [],
    EffectsModule.forRoot([]),
    AuthenticationStoreModule,
    StoreModule.forFeature(fromApp.appKey, fromApp.reducer),
    StoreModule.forFeature(fromInfluencer.influencerKey, fromInfluencer.reducer),
    EffectsModule.forFeature([AppEffects, InfluencerEffects]),
  ],
  exports: [StoreModule]
})
export class AppStoreModule { }
