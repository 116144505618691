import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './app.actions';

export const appKey = 'app';

export interface AppState {
    userGroup: any;
}

export const initialAppState: AppState = {
    userGroup: null,
};

export const appReducers = {
    getPublicUserGroup(state) {
        return { ...state};
    },
    getPublicUserGroupSuccess(state, { userGroup }) {
        return { ...state, userGroup};
    },
    getPublicUserGroupError(state, { error }) {
        return { ...state, loginError: 'Error getting public user group' };
    },

};

export function reducer(
    state: AppState | undefined,
    action: Action
) {
    return createReducer(
        initialAppState,
        on(actions.getPublicUserGroup, appReducers.getPublicUserGroup),
        on(actions.getPublicUserGroupSuccess, appReducers.getPublicUserGroupSuccess),
        on(actions.getPublicUserGroupFail, appReducers.getPublicUserGroupError),
    )(state, action);
}
