import { createAction, props } from '@ngrx/store';


export const getPublicUserGroup = createAction(
    '[App] Get public user group',
    props<{ typeUserGroup: string; queryValue: string }>()
);

export const getPublicUserGroupSuccess = createAction(
    '[App] Get public user group success',
    props<{ userGroup: any }>()
);

export const getPublicUserGroupFail = createAction(
    '[App] Get public user group fail',
    props<{ error: any }>()
);
