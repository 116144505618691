import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {DOCUMENT} from '@angular/common';
import {Store} from "@ngrx/store";
import {State} from "./store";

import {
  getPublicUserGroup,
} from './store/app/app.actions';
import * as actions from "./store/influencer/influencer.actions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    const platformId = environment.platform_id;
    let miniLogoUrl = '';
    if (platformId === 'binfluencer'){
      miniLogoUrl = '/assets/img/png/binfluencer_favicon.png';
    } else if (platformId === '11posts') {
      miniLogoUrl = '/assets/img/ico/11posts.ico';
    }
    this._document.getElementById('appFavicon').setAttribute('href', miniLogoUrl);

    this.store.dispatch(getPublicUserGroup({typeUserGroup: 'platform_id',  queryValue: platformId }));

    if (document.location.pathname.includes('app/')) {
      this.store.dispatch(actions.getInfluencerAvatar());
    }
  }
}
