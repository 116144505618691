import { createAction, props } from '@ngrx/store';


export const getInfluencerAvatar = createAction(
    '[Influencer] Get influencer avatar',
);

export const getInfluencerAvatarSuccess = createAction(
    '[Influencer] Get influencer avatar success',
    props<{ avatar: any }>()
);

export const getInfluencerAvatarFail = createAction(
    '[Influencer] Get influencer avatar fail',
    props<{ error: any }>()
);
