import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PrivateGuard} from "./guards/private.guard";

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/public/public.module').then(
        mod => mod.PublicModule
      ),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./features/private/private.module').then(
        mod => mod.PrivateModule
      ),
    canActivate: [PrivateGuard]
  },
  { path: 'app', pathMatch: 'full', redirectTo: '/app/profile' },
  { path: '**', pathMatch: 'full', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
