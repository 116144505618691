import {SHA256, HmacSHA256} from 'crypto-js';
import {AwsSignatureInputData} from './aws-signature-input.model';


export class AwsSignature {

  constructor() {
  }

  generateSignature(input: AwsSignatureInputData, currentDate: Date = new Date()): Object {
    const {canonicalHeaders, dateStamp, amzDate} = this.prepareCanonicalHeaders(currentDate, input);
    const {canonicalRequest, signedHeaders} = this.prepareCanonicalRequest(input, canonicalHeaders);
    const {stringToSign, algorithm, credentialScope} = this.generateStringToSign(dateStamp, input, amzDate, canonicalRequest);
    const signature = this.signString(input, dateStamp, stringToSign);
    const authorizationHeader = this.generateAuthorizationHeader(algorithm, input, credentialScope, signedHeaders, signature);

    return {
      'Content-Type': input.contentType,
      'X-Amz-Date': amzDate,
      'Authorization': authorizationHeader,
      'X-Amz-Security-Token': input.sessionToken
    };
  }

  private generateAuthorizationHeader(algorithm: string, input: AwsSignatureInputData, credentialScope: string, signedHeaders: string, signature: any) {

    return algorithm + ' ' + 'Credential=' + input.accessKey + '/' + credentialScope + ', ' + 'SignedHeaders=' + signedHeaders + ', ' + 'Signature=' + signature;
  }

  private signString(input: AwsSignatureInputData, dateStamp: string, stringToSign: string) {
    const signingKey = this.getSignatureKey(input.secretKey, dateStamp, input.region, input.service);

    return HmacSHA256(stringToSign, signingKey).toString();
  }

  private generateStringToSign(dateStamp: string, input: AwsSignatureInputData, amzDate: string, canonicalRequest: string) {
    const algorithm = 'AWS4-HMAC-SHA256';
    const credentialScope = dateStamp + '/' + input.region + '/' + input.service + '/' + 'aws4_request';
    const stringToSign = algorithm + '\n' + amzDate + '\n' + credentialScope + '\n' + SHA256(canonicalRequest).toString();

    return {stringToSign, algorithm, credentialScope};
  }

  private prepareCanonicalRequest(input: AwsSignatureInputData, canonicalHeaders: string) {
    const signedHeaders = 'content-type;host;x-amz-date;x-amz-security-token';
    const payloadHash = SHA256(input.requestParameters).toString();
    const canonicalRequest = input.method + '\n' + input.canonicalUri + '\n'
      + input.canonicalQuerystring + '\n' + canonicalHeaders + '\n\n'
      + signedHeaders + '\n' + payloadHash;

    return {canonicalRequest, signedHeaders};
  }

  private prepareCanonicalHeaders(currentDate: Date, input: AwsSignatureInputData) {
    const amzDate = currentDate.toISOString().replace(/-|:|\..{3}/g, '');
    const dateStamp = amzDate.substr(0, 8);
    const canonicalHeaders = 'content-type:' + input.contentType + '\n' + 'host:'
      + input.host + '\n' + 'x-amz-date:' + amzDate + '\n' + 'x-amz-security-token:' + input.sessionToken;
    return {canonicalHeaders, dateStamp, amzDate};
  }

  private getSignatureKey(key: string, dateStamp: string, regionName: string, serviceName: string): any {
    const kDate = HmacSHA256(dateStamp, 'AWS4' + key);
    const kRegion = HmacSHA256(regionName, kDate);
    const kService = HmacSHA256(serviceName, kRegion);
    const kSigning = HmacSHA256('aws4_request', kService);
    return kSigning;
  }
}
