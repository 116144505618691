import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    switchMap,
    catchError,
    map,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import * as actions from './influencer.actions';
import {InfluencerService} from "./influencer.service";


@Injectable()
export class InfluencerEffects {
    constructor(
        public router: Router,
        private service: InfluencerService,
        private actions$: Actions,

    ) {}


    getInfluencerAvatar$ = createEffect(() => this.actions$.pipe(
        ofType(actions.getInfluencerAvatar),
        switchMap( action => {
                return this.service.getInfluencerAvatar().pipe(
                    map(avatar => {
                        return actions.getInfluencerAvatarSuccess({ avatar }); }),
                    catchError(error => of(actions.getInfluencerAvatarFail({ error })))
                );
            }
        )
    ));


}
