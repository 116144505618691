import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';


@Injectable({
    providedIn: 'root',
})
export class InfluencerService {

    private apiUrl = environment.aws_resources.lambda_endpoint.api;

    constructor(
        private http: HttpClient) {}


    getInfluencerAvatar(){
        return this.http.get(this.apiUrl + 'influencer-influencer/influencer/avatar');
    }

    getInfluencerCampaigns() {
        return this.http.get(this.apiUrl + 'influencer-campaigns/campaigns');
    }

    getInfluencerMedias(influencerId, medias) {
        return this.http.post(this.apiUrl + 'influencer/icm/save_ig_pictures/' + influencerId, {medias});
    }

    getInfluencerCampaign(campaignId) {
        return this.http.get(this.apiUrl + 'influencer-campaigns/campaign/' + campaignId);
    }

    getInfluencerMetrics() {
        return this.http.get(this.apiUrl + 'influencer-influencer/influencer/metrics');
    }

    getShareInfluencerUrl(influencerId) {
        let params = new HttpParams();
        params = params.append('platform_id', environment.platform_id);
        return this.http.get(this.apiUrl + 'influencer/icm/' + influencerId + '/share', { params });
    }

}
