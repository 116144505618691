import { Injectable } from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {of} from "rxjs";
import * as actions from "./authentication.actions";
import {AuthenticationStoreModule} from "./authentication-store.module";
import {Store} from "@ngrx/store";
import {AuthenticationState} from "./authentication.reducers";


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private apiUrl = environment.aws_resources.lambda_endpoint.api;

  constructor(
              private http: HttpClient,
              private _authenticationService: AuthenticationService,
              public store: Store<AuthenticationState>,
  ) {}



  loginFacebookToken(facebookToken: string) {
    let params = new HttpParams();
    params = params.append('code', facebookToken);
    return this.http.get(this.apiUrl + 'influencer-authentication/callback', { params }).pipe(
        map((credentials: any[]) => credentials.map(AuthenticationService.parseInstagramAccountsResponse))
    );
  }

  getCognitoCredentials(instagramAccount) {
    let params = new HttpParams();
    params = params.append('instagram_id', instagramAccount.instagram_id);
    params = params.append('instagram_business_account_id', instagramAccount.instagram_business_account_id);
    params = params.append('instagram_username', instagramAccount.instagram_username);
    params = params.append('instagram_profile_picture_url', instagramAccount.instagram_profile_picture_url);
    params = params.append('facebook_id', instagramAccount.facebook_id);

    return this.http.get(this.apiUrl + 'influencer-authentication/credentials', {observe: 'response', params});
  }

  private static parseInstagramAccountsResponse(user) {
    return {
      instagram_id: user.instagram_id,
      instagram_business_account_id: user.instagram_business_account_id,
      instagram_username: user.instagram_username,
      instagram_profile_picture_url: user.instagram_profile_picture_url,
      facebook_id: user.facebook_id,
    };
  }

  // refreshSessionToken() {
  //   this.store.dispatch(actions.getCognitoCredentials({ instagramAccount: JSON.parse(localStorage.getItem('user')) }))
  // }

}
