import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    InfluencerState,
    influencerKey
} from './influencer.reducers';

export const selectInfluencer = createFeatureSelector<InfluencerState>(influencerKey);



export const selectInfluencerAvatar = createSelector(
    selectInfluencer,
    state => state.avatar
);
