import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../../../store";
import {CurrentUserService} from "../../../../core/services/auth/current-user.service";
import * as actions from "../../../../store/influencer/influencer.actions"
import * as selectorsApp from '../../../../store/app/app.selectors';
import * as selectorsInfluencer from '../../../../store/influencer/influencer.selectors';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit,  AfterViewChecked, OnDestroy {

  username :string;
  platformBrandLogo :string;
  avatar: string;

  private subscriptions = [];

  constructor(
      private _serviceCurrentUser: CurrentUserService,
      public store: Store<State>,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewChecked() {
    this.subscriptions.push(
        this.store.select(selectorsApp.selectPublicUserGroup).subscribe(userGroup => {
          if (userGroup !== null ) {
            this.platformBrandLogo = userGroup.logo_url;
            this.cdr.detectChanges();
          }
        }),
        this.store.select(selectorsInfluencer.selectInfluencerAvatar).subscribe(avatar => {
          if (avatar !== null ) {
            this.username = avatar.name;
            this.avatar = avatar.avatar;
            this.cdr.detectChanges();
          }
        })
    );
  }

  logout() {
    this._serviceCurrentUser.userLogOut();
  }

}
