import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    AppState,
    appKey
} from './app.reducers';

export const selectApp = createFeatureSelector<AppState>(appKey);



export const selectPublicUserGroup = createSelector(
    selectApp,
    state => state.userGroup
);
