import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root',
})
export class AppService {

    private apiUrl = environment.aws_resources.lambda_endpoint.api;

    constructor(
        private http: HttpClient) {}


    getPublicUserGroup(typeUserGroup, queryValue): Observable<any> {
        return this.http.get(this.apiUrl + 'plans/get_public_user_group' + '/' + typeUserGroup + '/' + queryValue);
    }

    processTheme(theme): any {
        Object.keys(theme).forEach(key => {
            document.documentElement.style.setProperty(key, theme[key]);
        });
    }
}
