import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {CurrentUserService} from "../core/services/auth/current-user.service";

@Injectable({
  providedIn: 'root'
})
export class PrivateGuard implements CanActivate {

  constructor(private _router: Router,
              private _currentUserService: CurrentUserService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this._currentUserService.currentUserValue)
      return true;

    if (JSON.parse(localStorage.getItem('user'))) {
      this._currentUserService.setUser(JSON.parse(localStorage.getItem('user')));
      return true;
    }


    this._router.navigate(['auth/login']);
    return false;
  }
}
